import React from "react";
import Navbar from "./Navbar";
import t11 from "../assets/gallery/t1-1.PNG";
import t12 from "../assets/gallery/t1-2.PNG";
import t13 from "../assets/gallery/t1-3.PNG";
import t14 from "../assets/gallery/t1-4.PNG";
import t15 from "../assets/gallery/t1-5.jpg";
import t16 from "../assets/gallery/t1-6.JPG";
import t17 from "../assets/gallery/t1-7.PNG";
import t18 from "../assets/gallery/t1-8.JPG";
import t19 from "../assets/gallery/t1-9.JPG";
import t110 from "../assets/gallery/t1-10.JPG";
import t111 from "../assets/gallery/t1-11.JPG";
import t112 from "../assets/gallery/t1-12.JPG";
import t113 from "../assets/gallery/t1-13.JPG";
import t114 from "../assets/gallery/t1-14.JPG";
import t115 from "../assets/gallery/t1-15.JPG";
import t116 from "../assets/gallery/t1-16.JPG";
import t117 from "../assets/gallery/t1-17.PNG";
import t118 from "../assets/gallery/t1-18.PNG";
import t119 from "../assets/gallery/t1-19.PNG";
import t120 from "../assets/gallery/t1-20.JPG";
import t121 from "../assets/gallery/t1-21.JPG";
import t122 from "../assets/gallery/t1-22.JPG";
import t123 from "../assets/gallery/t1-23.jpg";
import t124 from "../assets/gallery/t1-24.jpg";

import t21 from "../assets/gallery/t2-1.PNG";
import t22 from "../assets/gallery/t2-2.PNG";
import t23 from "../assets/gallery/t2-3.PNG";
import t24 from "../assets/gallery/t2-4.PNG";
import t25 from "../assets/gallery/t2-5.PNG";
import t26 from "../assets/gallery/t2-6.PNG";
import t27 from "../assets/gallery/t2-7.PNG";
import t28 from "../assets/gallery/t2-8.PNG";
import t29 from "../assets/gallery/t2-9.PNG";
import t210 from "../assets/gallery/t2-10.PNG";
import t211 from "../assets/gallery/t2-11.PNG";
import t212 from "../assets/gallery/t2-12.PNG";
import t213 from "../assets/gallery/t2-13.PNG";
import t214 from "../assets/gallery/t2-14.PNG";
import t215 from "../assets/gallery/t2-15.JPG";
import t216 from "../assets/gallery/t2-16.JPG";
import t217 from "../assets/gallery/t2-17.JPG";
import t218 from "../assets/gallery/t2-18.JPG";
import t219 from "../assets/gallery/t2-19.JPG";
import t220 from "../assets/gallery/t2-20.JPG";
import t221 from "../assets/gallery/t2-21.JPG";
import t222 from "../assets/gallery/t2-22.JPG";
import t223 from "../assets/gallery/t2-23.JPG";
import t224 from "../assets/gallery/t2-24.JPG";
import t225 from "../assets/gallery/t2-25.JPG";
import t226 from "../assets/gallery/t2-26.JPG";
import t227 from "../assets/gallery/t2-27.JPG";
import t228 from "../assets/gallery/t2-28.JPG";
import t229 from "../assets/gallery/t2-29.JPG";
import t230 from "../assets/gallery/t2-30.JPG";
import t231 from "../assets/gallery/t2-31.JPG";
import t232 from "../assets/gallery/t2-32.JPG";
import t233 from "../assets/gallery/t2-33.JPG";
import t234 from "../assets/gallery/t2-34.JPG";
import t235 from "../assets/gallery/t2-35.PNG";
import t236 from "../assets/gallery/t2-36.JPG";
import t237 from "../assets/gallery/t2-37.JPG";
import t238 from "../assets/gallery/t2-38.JPG";
import t239 from "../assets/gallery/t2-39.jpg";
import t240 from "../assets/gallery/t2-40.jpg";

import t31 from "../assets/gallery/t3-1.PNG";
import t32 from "../assets/gallery/t3-2.PNG";
import t33 from "../assets/gallery/t3-3.PNG";
import t34 from "../assets/gallery/t3-4.PNG";
import t35 from "../assets/gallery/t3-5.PNG";
import t36 from "../assets/gallery/t3-6.PNG";
import t37 from "../assets/gallery/t3-7.PNG";
import t39 from "../assets/gallery/t3-9.PNG";
import t310 from "../assets/gallery/t3-10.JPG";
import t311 from "../assets/gallery/t3-11.JPG";
import t312 from "../assets/gallery/t3-12.PNG";
import t313 from "../assets/gallery/t3-13.PNG";
import t314 from "../assets/gallery/t3-14.PNG";
import t315 from "../assets/gallery/t3-15.jpeg";
import t316 from "../assets/gallery/t3-16.PNG";
import t317 from "../assets/gallery/t3-17.JPG";
import t318 from "../assets/gallery/t3-18.JPG";
import t319 from "../assets/gallery/t3-19.JPG";

import t41 from "../assets/gallery/t4-1.JPG";
import t42 from "../assets/gallery/t4-2.JPG";
import t43 from "../assets/gallery/t4-3.jpg";
import t44 from "../assets/gallery/t4-4.jpg";

const Gallery = () => {
  document.title = "Awesome Cakes | Gallery";

  return (
    <div className="aboutpage">
      <Navbar />

      <hr className="hrtag-about" />

      <div className="story">
        <h2>Products</h2>
        <p>
          At Awesome Cakes by Ammu, we pride ourselves on our vast range of
          delectable cakes that cater to every occasion and palate. With Ammu's
          expertise and passion for baking, every cake is meticulously crafted
          to perfection, ensuring not only stunning aesthetics but also
          irresistible taste. Whether you're celebrating a birthday, wedding,
          anniversary, or any special moment in life, our cakes are guaranteed
          to delight and impress. Trust us to turn your vision into a
          mouthwatering reality, because at Awesome Cakes by Ammu, we believe
          that every cake should be as unique and unforgettable as the occasion
          it celebrates.
        </p>
        <b>
          <p style={{ color: "#8b6a6a" }}>
            "Explore an array of captivating designs by connecting with me on
            Instagram and Facebook. Follow along for a journey through
            creativity and inspiration!"
          </p>
        </b>
      </div>

      <div className="story">
        <h2>Our Product Gallery</h2>
        <div className="gallery">
          <img src={t41} alt="" />
          <img src={t42} alt="" />
          <img src={t43} alt="" />
          <img src={t44} alt="" />

          <img src={t33} alt="" />
          <img src={t39} alt="" />
          <img src={t36} alt="" />
          <img src={t311} alt="" />
          <img src={t310} alt="" />
          <img src={t32} alt="" />
          <img src={t34} alt="" />
          <img src={t35} alt="" />
          <img src={t37} alt="" />
          <img src={t31} alt="" />
          <img src={t312} alt="" />
          <img src={t313} alt="" />
          <img src={t314} alt="" />
          <img src={t315} alt="" />
          <img src={t316} alt="" />
          <img src={t317} alt="" />
          <img src={t318} alt="" />
          <img src={t319} alt="" />

          <img src={t21} alt="" />
          <img src={t22} alt="" />
          <img src={t23} alt="" />
          <img src={t24} alt="" />
          <img src={t25} alt="" />
          <img src={t26} alt="" />
          <img src={t27} alt="" />
          <img src={t28} alt="" />
          <img src={t29} alt="" />
          <img src={t210} alt="" />
          <img src={t211} alt="" />
          <img src={t212} alt="" />
          <img src={t213} alt="" />
          <img src={t214} alt="" />
          <img src={t215} alt="" />
          <img src={t216} alt="" />
          <img src={t217} alt="" />
          <img src={t218} alt="" />
          <img src={t219} alt="" />
          <img src={t220} alt="" />
          <img src={t221} alt="" />
          <img src={t222} alt="" />
          <img src={t223} alt="" />
          <img src={t224} alt="" />
          <img src={t225} alt="" />
          <img src={t226} alt="" />
          <img src={t227} alt="" />
          <img src={t228} alt="" />
          <img src={t229} alt="" />
          <img src={t230} alt="" />
          <img src={t231} alt="" />
          <img src={t232} alt="" />
          <img src={t233} alt="" />
          <img src={t234} alt="" />
          <img src={t235} alt="" />
          <img src={t236} alt="" />
          <img src={t237} alt="" />
          <img src={t238} alt="" />
          <img src={t239} alt="" />
          <img src={t240} alt="" />

          <img src={t121} alt="" />
          <img src={t11} alt="" />
          <img src={t12} alt="" />
          <img src={t13} alt="" />
          <img src={t14} alt="" />
          <img src={t15} alt="" />
          <img src={t16} alt="" />
          <img src={t17} alt="" />
          <img src={t18} alt="" />
          <img src={t19} alt="" />
          <img src={t110} alt="" />
          <img src={t111} alt="" />
          <img src={t112} alt="" />
          <img src={t113} alt="" />
          <img src={t114} alt="" />
          <img src={t115} alt="" />
          <img src={t116} alt="" />
          <img src={t117} alt="" />
          <img src={t118} alt="" />
          <img src={t119} alt="" />
          <img src={t120} alt="" />
          <img src={t122} alt="" />
          <img src={t123} alt="" />
          <img src={t124} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
