import React from "react";
import Navbar from "./Navbar";
const TNC = () => {
  return (
    <div style={{ padding: "10px 50px" }}>
      <Navbar />
      <hr className="hrtag-about" />
      <div className="story">
        <h2>Terms and Conditions</h2>
        <p>
          <ol>
            <li>
              {" "}
              A minimum deposit of 50% is required to secure any booking. This
              deposit confirms your commitment and reserves your spot on our
              baking schedule.{" "}
            </li>

            <li>
              We bake both Egg and Eggless cakes and desserts. However, please
              note that our kitchen handles ingredients such as nuts, wheat,
              dairy, and eggs. We recommend that individuals with severe
              allergies refrain from consuming our products.
            </li>
            <li>
              Please inform us of any dietary restrictions or preferences when
              placing your order. Our baked goods are homemade, not
              state-inspected, and operate under the Minnesota Cottage Food Law
            </li>
            <li>
              All deposits are non-refundable. If an order is canceled under
              exceptional circumstances, we will offer the opportunity to use
              the deposit for another order placed within one year from the
              original order date, pending our availability.
            </li>
            <li>
              We strive to meet your color and presentation requests, but please
              understand that achieving an exact color match is not always
              possible. Each of our creations is one-of-a-kind.
            </li>
            <li>
              While we take inspiration from previous designs, both ours and
              others', we cannot promise exact duplications of any cake,
              including those we've made before. Responsibility for the product
              transfers to the customer upon pickup or delivery. If the product
              meets the agreed specifications, we cannot accept liability for
              any subsequent complaints.
            </li>
            <li>
              {" "}
              Our "NO SMASHING POLICY" is strictly enforced to ensure the
              structural integrity of the cake, as many of our designs
              incorporate support materials for stability. Please be aware that
              some cake decorations are not meant to be eaten and should be
              removed before serving.
            </li>
            <li>
              {" "}
              To ensure we can accommodate your request, we suggest placing
              orders 3-4 weeks prior to your event. Due to limited capacity,
              booking early is strongly advised.
            </li>
            <li>
              <b>Note:</b> For bespoke cake designs, we require a notice of 2-3
              months to ensure sufficient time for the planning and creation
              process. Exceptions may be made on a case-by-case basis, so please
              reach out to discuss your needs.
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
};

export default TNC;
