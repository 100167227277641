import React from "react";
import "../styles/about.css";
import Navbar from "./Navbar";

import c1 from "../assets/cake_2.jpeg";
import c2 from "../assets/cake1.jpeg";

const About = () => {
  document.title = "Awesome Cakes | Testimonials";
  return (
    <div className="aboutpage">
      <Navbar />

      <hr className="hrtag-about" />
      <div className="story">
        <h2>Testimonails</h2>
      </div>

      <div className="testimonials">
        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.6435-1/123328608_10158931641218769_6262227512918115721_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=HlFY0xg6HBYAX_bMKWc&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCNbBem8zX2rWqdDLqGlgF_kjbhpu0CRdYq8KvZXldxJw&oe=661E1648"
              alt=""
              className="profilepic"
            />

            <p>Sadia Kashif </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Loved the cake, it was awsum. Taste was good as well. Service was on
            spot. Thank you so much. Will order again🥰
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/432389794_10232647711044213_5277805429261931452_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=0af19_vNpWIAX_qJHIr&_nc_oc=AQkqA24L57V0wDkcP3qI6lMqKN5NRAKZ4e0eVvVYChYTd_zEPLfaTB7IJiU2yrLZQwo&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfC_bu-dN9yfwgCKr9YTQuYe0s85cYZEQDC9Gr2zEyCWrA&oe=65FADC4F"
              alt=""
              className="profilepic"
            />
            <p>Ortega-Flores Jazzian </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Thank you awsome cakes! For making son's first birthday memorable
            with a gorgeous cake. Ans thank you as well for making his for his
            "smash the cake pictorial" I love it! Everyone in the party says
            they are gorgeous!!
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/302188435_5614071451964956_7127644639549763375_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SaAa0aky16IAX_ei-Km&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfB4OhA20N-QU0HzumihkWQyTR8k4tkU1noDo6xciwUCDw&oe=65FC7522"
              alt=""
              className="profilepic"
            />
            <p>Holly Tice </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Great cake exactly what I wanted I got. Great communication timely
            manner. Highly recommended.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/415058819_3143256815808414_9161889763742424465_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=I2FFoTkK2CkAX_4X4uv&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCuZmTsKwTEIT2Aiecug1-KmpQXtvU3Guug9fxiPD_bXQ&oe=65FC066E"
              alt=""
              className="profilepic"
            />
            <p>Narmona Faeq Khaledi </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Thank you very much awesome cakes the taste is so good this is third
            time every time the cake is amazing 😊❤️5 stars ⭐️
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/366020846_7300506643299389_8744717892603754334_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=lq5svYeMtPYAX8nMvxX&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfAOpmUhS3UzOMAEZRqFKNhu_iYUHmau8r4rhs_AzAPupw&oe=65FA7F67"
              alt=""
              className="profilepic"
            />
            <p>Ambika Hemraj </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Highly recommended!! The cake was exactly what we wanted, it’s
            tasted so good.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/251790516_10159965990539155_762715705293847874_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=MShl_DXvrxYAX9i2zNS&_nc_oc=AQm4W4rKGfq0LD7QwwiHeXqsXaJ3dft7frZe0_8Ltw0JADlCDXGNCkrq_cOU5vGken0&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfDb7eEmYhycZGU1SBSa3ebHWmiPqUIroIENq2-NFkydqg&oe=65FC28C0"
              alt=""
              className="profilepic"
            />
            <p>Sravv Anii </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Amazing Cake and an excellent service.. My son was obsessed with
            this cake.. would definitely recommend to all of my friends..! 😀
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.18169-1/24059054_313790709101734_4659129944777224412_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=aIMC_bDSpWUAX8dvxzO&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfALJxUuMxI3Dbp9Yritslmnj9ovdW_4LRqKSU4YqYib4A&oe=661DF09F"
              alt=""
              className="profilepic"
            />
            <p>Sherman Powell</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            This was my fist time ordering out of my network and I was very
            satisfied. Taste was great personation was Wonder! Delivery was late
            glad I set it early and not late. Over all wonderful experience, I
            will definitely continue to order with them.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/315653402_5817892018273254_3106436390711554721_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=4taeJHYLlmcAX_4tFAd&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfDNZQJasLiSrni0NQ8ppkTNlqjBTnJU_x1RZDbGNuPHqQ&oe=65FC1B76"
              alt=""
              className="profilepic"
            />
            <p>Chevelle Bradley</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Yes Yes Yes These are some Awesome Blessed gifted people that made
            my granddaughter bday cake last weekend ❤️❤️❤️❤️❤️❤️❤️❤️ I give them
            10⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐'s
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.30497-1/143086968_2856368904622192_1959732218791162458_n.png?stp=cp0_dst-png_p60x60&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ydxhzA9VjJsAX9zO3VC&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCNk4nvKHABiVT6yIhEki1lN_9t_Eb3wV8ermAb8K2reg&oe=661DE8B8"
              alt=""
              className="profilepic"
            />
            <p>Veronica Galindo </p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            I highly recommend this cake place I am very excited to have ordered
            here and trust that it was exactly how I expected! I give this a 5
            star review! Very much appreciated!
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.30497-1/143086968_2856368904622192_1959732218791162458_n.png?stp=cp0_dst-png_p60x60&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ydxhzA9VjJsAX9zO3VC&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCNk4nvKHABiVT6yIhEki1lN_9t_Eb3wV8ermAb8K2reg&oe=661DE8B8"
              alt=""
              className="profilepic"
            />
            <p>Narmona Alkhaledi</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Amazing cake tast so good professional customer service my daughter
            love this 🥰🥰 thank you you maid her day ❤️
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/324729341_1142418426464795_8624387752344435629_n.jpg?stp=cp6_dst-jpg_p60x60&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=gdUIkTMoBwMAX_q7KBK&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfB_9TZDusCOuAel_LNhq_zEbExz-iVO7B0pLxe531BCxw&oe=65FB8BF7"
              alt=""
              className="profilepic"
            />
            <p>Seli Alem</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">Highly recommended best chocolate cake</p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t31.18172-1/10470795_290808827775713_2142076337349625329_o.jpg?stp=c10.0.60.60a_cp0_dst-jpg_p60x60&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=RkUgDB33cTMAX8TXPUy&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfBYjOmRLSHtyB3CIZTgv6gZqHwWzWSpSX_2PBzTOhHaQw&oe=661E108B"
              alt=""
              className="profilepic"
            />
            <p>Anvesha Raichand</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Loved the Mickey mouse design..and the rasmalai flavor was awesome
            too.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/418462059_7431253913573570_3602550305557516262_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SKOnecqfyIgAX_MBXB4&_nc_oc=AQk8wG0yOUTdFRkLSxTQQYkY7O3QFHbQCLpy2ki1ylQEX2knoJ5aun9BXJEyIuXQ1f0&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfA7MeZrfKuTbsO7XI7oDPZ3sGJ4yNvs9NXZosJiSyyC9g&oe=65FBD157"
              alt=""
              className="profilepic"
            />
            <p>Bolo Sayavedra</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Highly recommended, very nice service and quality. Thanks again.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/316814723_10160094452101391_2926825951600508413_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=tfzVkhfAKHUAX9AYdQq&_nc_oc=AQlsn6Nvmigcy0fEebWukgOcEd8GkRU2HMrRhq7TcXIwQaimNJRGUdIHbMUByJG8klY&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfA8FG4bcHXTNHHeVSKNXqKJIfEt7ZN2Xv_2VrQJFqTStw&oe=65FB2BDE"
              alt=""
              className="profilepic"
            />
            <p>Swetha Kadari</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Loved the cake taste n decorations on the cake. She is very
            responsive and even delivered the cake. Very impressed
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t31.18172-1/10495705_776475079058986_4554075759288610611_o.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=sl_9o_LpHhMAX-sZCg_&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfBd8pbxZq7ZEu1e6UiNMnOZNPdZP6He_wZcomjbossnbA&oe=661E1321"
              alt=""
              className="profilepic"
            />
            <p>Shivani Dhammam</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Recently we ordered a Black Forest cake for Father’s Day celebration
            & it turned out to be a delicious well made cake. It was well liked
            and relished by everyone . So therefore I urge everyone in and
            around Eagan to give Awesome cakes by Ammu a Try! You won’t be
            disappointed.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/241272849_10159111447913880_5070192665430487458_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=UI2t_7jyazUAX8M0v5C&_nc_oc=AQkLiljyvwO3wiEgEzt90Yq4ynh5iGlymO_2xKvB9zdixn7QOlurz86hbvc3TIhLnZc&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfB22y-rgWPHzjxI25Kix1nlrqpWJe4duMiwCPlrAClytw&oe=65FBFA48"
              alt=""
              className="profilepic"
            />
            <p>Ruby Shah</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Ammu’s cakes are amazing! I just ordered the rasamalai cake and you
            can literally taste the flavor in every bite. Not heavy at all and
            perfectly light and moist! We already know which cake we are going
            to try next 🙂
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.6435-1/189913065_4578775315483919_6036419491795871533_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=iAbCd1MxO48AX8TXc4l&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCD7XzKmtWzZYmoDTUAzhe_a4KWGwSPfVUyoEfsEURrTA&oe=661DF5CD"
              alt=""
              className="profilepic"
            />
            <p>Sarija Janardh</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            I ordered awesome cakes for my sons' 3rd birthday and the cake was
            custom made for the transportation theme i had chosen. Awesome cakes
            was very prompt in delivering the cake on time and the taste was
            mind blowing. Everyone in the party asked about the cake and was
            impressed with the texture and taste .Also ammu custom made the cake
            exactly as i asked her to .please see the pictures attached to see
            it yourself. i definitely recommend awesome cakes.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.6435-1/207275073_10224277418418256_9209128698654348664_n.jpg?stp=c0.0.60.60a_cp0_dst-jpg_p60x60&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=2yCJ_PtVaBUAX_2xgwn&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfAhBVDVv6IIATnrF0woYL2aiIdnKq8hOFtQNF3WxvYkiw&oe=661E092F"
              alt=""
              className="profilepic"
            />
            <p>Sameera Kalidindi</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            She made an amazing doll cake on a very short notice. Everyone loved
            it.
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.30497-1/143086968_2856368904622192_1959732218791162458_n.png?stp=cp0_dst-png_p60x60&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ydxhzA9VjJsAX9zO3VC&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCNk4nvKHABiVT6yIhEki1lN_9t_Eb3wV8ermAb8K2reg&oe=661DE8B8"
              alt=""
              className="profilepic"
            />
            <p>Nalini Rampilla</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            She made a wonderful cake for my daughters birthday , the flavor was
            too gud and she so friendly to talk to her and did the job what I
            needed I recommend everyone to taste her cake, thank u
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t1.30497-1/143086968_2856368904622192_1959732218791162458_n.png?stp=cp0_dst-png_p60x60&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ydxhzA9VjJsAX9zO3VC&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfCNk4nvKHABiVT6yIhEki1lN_9t_Eb3wV8ermAb8K2reg&oe=661DE8B8"
              alt=""
              className="profilepic"
            />
            <p>Pavani Kalyan</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            She made a birthday cake for my daughter n son birthday it’s taste’s
            good everyone like the cake. Thanks Awesome cakes
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/420179407_7021231757992928_6000762630993300765_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=98Gl8iK3WGkAX9PS91v&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfAIM4GmA9yBpwOR3lZZNnE0gt4fZjvs1UK-7U8z7Kt71Q&oe=65FAEB6A"
              alt=""
              className="profilepic"
            />
            <p>Priyanka Pedapudi</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            I have order the cake my son's birthday and my son loves cake and it
            taste really good...
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/271264651_4455333811243778_4337927437749017218_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_ohc=_d_6tnvY-lcAX-oL41-&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfDrnfeQhQHTB0QWdlbiSG7anl2tmyM_Kg6HuW5TCzdiaw&oe=65FB469A"
              alt=""
              className="profilepic"
            />
            <p>Haritha Arvindh</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            the outlook of the cake was awesome nd cupcakes r very delicious
            😋😍
          </p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/244181574_4680374128681275_2745987150256635141_n.jpg?stp=c1.0.59.60a_cp0_dst-jpg_p60x60&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Qj1Lf1IWYw8AX8BRVsh&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfC3bV9vD6JXvNYuHeY84GUhGrBiJr5JBZO-GI6_SRQh2A&oe=65FA972C"
              alt=""
              className="profilepic"
            />
            <p>Varun Kapuganty</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">Delicious and awesome Cake..</p>
        </div>

        <div className="test">
          <div className="name">
            <img
              src="https://scontent.fhyd10-1.fna.fbcdn.net/v/t39.30808-1/358053469_2095460957325854_3312443607035336464_n.jpg?stp=cp0_dst-jpg_p60x60&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=btbeW-Pg448AX_Axcyp&_nc_ht=scontent.fhyd10-1.fna&oh=00_AfC33gyQt3Ze0RCbpgejXE6aDc3AM4hVw5ogXoCPoP5BIw&oe=65FCB623"
              alt=""
              className="profilepic"
            />
            <p>Vico Lorputa</p>
            <div className="ratings">
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
              <i class="fa-solid fa-star" style={{ color: "yellow" }}></i>
            </div>
          </div>
          <p className="feedback">
            Omg my cake was awesome, just like I expected it to be. Thanks so
            much, Awesome Cake. you guys are great in cake designs.
          </p>
        </div>

        <p style={{ fontSize: "20px" }}>
          For more reviews and feedbacks please follow our{"  "}
          <a
            href="https://www.facebook.com/profile.php?id=100061038013601&mibextid=LQQJ4d"
            target="_blank"
            rel="noreferrer"
            style={{ color: "dodgerblue", textDecoration: "none" }}
          >
            Facebook <i className="fa-brands fa-facebook"></i>
          </a>
          {"  "}
          and{"  "}
          <a
            href="https://www.instagram.com/awesomecakesbyammu/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "dodgerblue", textDecoration: "none" }}
          >
            Instagram <i className="fa-brands fa-instagram"></i>
          </a>{" "}
          pages
        </p>
      </div>
    </div>
  );
};

export default About;
