import React from "react";
import "../styles/home.css";
import Navbar from "./Navbar";
import Carousel from "./Carousel";
import cake from "../assets/cake.JPG";
import cake2 from "../assets/cake2.jpeg";
import { Link } from "react-router-dom";

const Home = () => {
  document.title = "Awesome Cakes";
  return (
    <>
      <div className="maincontainer">
        <Navbar />
        <div className="title-section">
          <div className="words">
            <h1 className="title">
              Indulge in Elegance, Taste the Artistry - Where every Cake tells a
              Delicious Tale
            </h1>
            <p className="para">
              Our cakes are more than desserts; they're edible stories crafted
              for your special moments. From whimsical birthday creations to
              elegant wedding masterpieces, each cake is a unique expression of
              joy and celebration
            </p>

            <div className="button2">
              <Link to="/gallery">
                {" "}
                <button>Explore More</button>{" "}
              </Link>
              <div className="circle2"> &rarr;</div>
            </div>
          </div>
          <div className="carousel">
            <Carousel />
          </div>
        </div>
      </div>
      <div className="aboutsection">
        <div className="content">
          <h2>Artisan Cakery by Ammu</h2>
          <p>
            Welcome to Awesome Cakes By Ammu, where dreams take shape in the
            form of exquisite theme, designer, and wedding cakes. I'm Ammu, the
            artistic mind behind the sweetness. With a passion for turning
            visions into edible masterpieces, each cake is a bespoke creation
            tailored to your unique style and theme.
          </p>
        </div>
        <div className="aboutcont">
          <img src={cake} alt="" className="about" />
        </div>
      </div>
      <div className="aboutsection mobile-reverse">
        <div className="aboutcont">
          <img src={cake2} alt="" className="about img2" />
        </div>
        <div className="content">
          <h2>Specializations</h2>
          <p>
            From whimsical designs to elegant wedding tiers, we specialize in
            bringing your ideas to life. Our commitment to craftsmanship and
            attention to detail ensures that every slice is a work of art. Let
            us be a part of your special moments, turning celebrations into
            cherished memories. Explore the world of Awesome Cakes By Ammu,
            where creativity meets confectionery perfection
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;
