import React, { useState, useEffect } from "react";

import t21 from "../assets/gallery/t2-1.PNG";
import t22 from "../assets/gallery/t2-2.PNG";
import t23 from "../assets/gallery/t2-3.PNG";
import t24 from "../assets/gallery/t2-4.PNG";
import t25 from "../assets/gallery/t2-5.PNG";
import t26 from "../assets/gallery/t2-6.PNG";
import t27 from "../assets/gallery/t2-7.PNG";
import t28 from "../assets/gallery/t2-8.PNG";
import t29 from "../assets/gallery/t2-9.PNG";
import t210 from "../assets/gallery/t2-10.PNG";

import t31 from "../assets/gallery/t3-1.PNG";
import t32 from "../assets/gallery/t3-2.PNG";
import t33 from "../assets/gallery/t3-3.PNG";
import t34 from "../assets/gallery/t3-4.PNG";
import t35 from "../assets/gallery/t3-5.PNG";
import t36 from "../assets/gallery/t3-6.PNG";
import t37 from "../assets/gallery/t3-7.PNG";

import t39 from "../assets/gallery/t3-9.PNG";
import t311 from "../assets/gallery/t3-11.JPG";
import t312 from "../assets/gallery/t3-12.PNG";
import t313 from "../assets/gallery/t3-13.PNG";
import t314 from "../assets/gallery/t3-14.PNG";
import t315 from "../assets/gallery/t3-15.jpeg";
import t316 from "../assets/gallery/t3-16.PNG";
import t317 from "../assets/gallery/t3-17.JPG";

import t41 from "../assets/gallery/t4-1.JPG";
import t42 from "../assets/gallery/t4-2.JPG";
import t43 from "../assets/gallery/t4-3.jpg";
import t44 from "../assets/gallery/t4-4.jpg";

import c1 from "../assets/cake1.jpeg";
import c2 from "../assets/cake_2.jpeg";

const Carousel = () => {
  let urls = [
    t41,
    t42,
    t43,
    t44,
    t31,
    t32,
    t33,
    t34,
    t35,
    t36,
    t37,
    t39,

    t311,
    t312,
    t313,
    t314,
    t315,
    t316,
    t317,
    c1,
    c2,
    t21,
    t22,
    t23,
    t24,
    t25,
    t26,
    t27,
    t28,
    t29,
    t210,
  ];

  const [imgsrc, setimgsrc] = useState(urls[0]);
  const [count, setcount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count > urls.length - 2) {
        setcount(0);
      } else {
        setimgsrc(urls[count]);
        setcount(count + 1);
      }
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [count, urls]);

  return <img src={imgsrc} alt="" className="activeimg" />;
};

export default Carousel;
