import React from "react";
import Navbar from "./Navbar";

const Contact = () => {
  document.title = "Awesome Cakes | Contact";
  return (
    <div style={{ padding: "10px 50px" }}>
      <Navbar />
      <div className="aboutsection">
        <div className="content">
          <h2>Get in Touch</h2>
          <ul
            style={{
              listStyle: "circle",
              fontSize: "18px",
              width: "100%",
              textAlign: "left",
              margin: "0",
              wordWrap: "break-word",
            }}
          >
            <li style={{ margin: "10px 0px", wordWrap: "break-word" }}>
              Email us at : <br /> awesomecakesbyammu@gmail.com
            </li>
            <li style={{ margin: "10px 0px", wordWrap: "break-word" }}>
              Address : Lakeville, Minnesota.
            </li>
            <li style={{ margin: "10px 0px", wordWrap: "break-word" }}>
              Contact us at :+1 848-219-4461
            </li>
          </ul>
        </div>
        <div className="content">
          <h2>Call us Here - Our Socials</h2>
          <div className="socials">
            <a
              href="https://www.instagram.com/awesomecakesbyammu/"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100061038013601&mibextid=LQQJ4d"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fa-brands fa-facebook-messenger"></i>
            </a>
            <a
              href="https://wa.me/18482194461"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <i class="fa-brands fa-whatsapp"></i>
            </a>
            <a
              href="mailto:awesomecakesbyammu@gmail.com"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <i class="fa-solid fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
