import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="details">
        <div className="det">
          <h3>Our Location</h3>
          <p>Lakeville, Minnesota</p>
        </div>
        <div className="det">
          <h3>Email us at</h3>
          <p>awesomecakesbyammu@gmail.com</p>
        </div>
        <div className="det">
          <h3>Contact Us at</h3>
          <p>+1 848-219-4461</p>
        </div>
        <div className="det">
          <h3>Our Socials</h3>
          <div className="socials">
            <a
              href="https://www.instagram.com/awesomecakesbyammu/"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100061038013601&mibextid=LQQJ4d"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fa-brands fa-facebook-messenger"></i>
            </a>
            <a
              href="https://wa.me/18482194461"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <i class="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
