import About from "./components/About";
import Contact from "./components/Contact";
import Flavours from "./components/Flavours";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Home from "./components/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Order from "./components/Order";
import FAQ from "./components/FAQ";
import TNC from "./components/TNC";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/testimonials" element={<About />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/flavours" element={<Flavours />} />
          <Route exact path="/order" element={<Order />} />
          <Route exact path="/tnc" element={<TNC />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
