import React, { useState, useRef, useEffect } from "react";
import "../styles/navbar.css";
import { Link, useLocation } from "react-router-dom";
import img from "../assets/logo.png";

const Navbar = () => {
  const location = useLocation();
  const navitems = useRef(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleclick = () => {
    console.log(navitems.current.classList);
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="navbar">
      <Link to="/">
        <img src={img} alt="" className="logo" />
      </Link>
      <div className="dropdown">
        <ul
          className={`nav-items${isNavOpen ? " translate" : ""}`}
          ref={navitems}
        >
          <li>
            <Link
              to="/"
              className={`link ${location.pathname === "/" ? "active" : ""}`}
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              to="/flavours"
              className={`link ${
                location.pathname === "/flavours" ? "active" : ""
              }`}
            >
              Flavours
            </Link>
          </li>
          <li>
            <Link
              to="/gallery"
              className={`link ${
                location.pathname === "/gallery" ? "active" : ""
              }`}
            >
              Gallery
            </Link>
          </li>
          <li>
            <Link
              to="/order"
              className={`link ${
                location.pathname === "/order" ? "active" : ""
              }`}
            >
              Order
            </Link>
          </li>
          <li>
            <Link
              to="/testimonials"
              className={`link ${
                location.pathname === "/testimonials" ? "active" : ""
              }`}
            >
              Testimonials
            </Link>
          </li>

          <li>
            <Link
              to="/faq"
              className={`link ${location.pathname === "/faq" ? "active" : ""}`}
            >
              F.A.Q
            </Link>
          </li>

          <li>
            <Link
              to="/tnc"
              className={`link ${location.pathname === "/tnc" ? "active" : ""}`}
            >
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={`link ${
                location.pathname === "/contact" ? "active" : ""
              }`}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>

      <div class="hamburger" onClick={handleclick}>
        <input type="checkbox" checked={isNavOpen} />
        <svg viewBox="0 0 32 32">
          <path
            class="line line-top-bottom"
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path class="line" d="M7 16 27 16"></path>
        </svg>
      </div>
    </div>
  );
};

export default Navbar;
