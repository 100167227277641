import React from "react";
import "../styles/about.css";
import Navbar from "./Navbar";
const Order = () => {
  document.title = "Awesome Cakes | How to Order";
  return (
    <div className="aboutpage">
      <Navbar />

      <hr className="hrtag-about" />
      <div className="story">
        <h2>How to place an Order</h2>
        <p style={{ textAlign: "center", lineHeight: "230%" }}>
          Please fill the following details in the form below, we’ll get back to
          you with in 24 hours.
          <br />
          <b>
            {" "}
            Name (Mandatory) <br />
            Phone # (Mandatory) <br />
            Email (Mandatory)
            <br />
            Event Date(Mandatory) <br />
            Number of Guests/Servings (Mandatory) <br />
            Theme of the Event (Mandatory) <br />
            Cake Flavor (Mandatory) <br />
            Client Notes/Customizations required (Mandatory): Send us text to be
            written on the cake and any other customizations that you would like
            to see on the cake. <br />
            Cake Design (Optional) <br />{" "}
          </b>
          Note: Feel free to send us your inspiration picture, we will be
          creating our own design inspired from the picture you sent. It need
          not be an exact replica of what you sent. <br /> <br />
          We make both Egg & Eggless cakes. We strictly do not cater for people
          with allergies. <br />
          Additional charges may apply for custom toppers and complex design.{" "}
          <br /> <br />
          <b>
            *All Items are made in my home kitchen operating under Minnesota
            Cottage Food Law & not subjected to state inspection. <br />{" "}
            **Allergens: Dairy, Eggs and Nuts
          </b>
        </p>
        <form
          action="mailto:awesomecakesbyammu@gmail.com"
          method="post"
          enctype="text/plain"
          className="form"
        >
          <label for="name">Name:</label>
          <br />
          <input
            type="text"
            id="name"
            name="name"
            required
            placeholder="Your Name here.."
          />
          <br />
          <label for="email">Email:</label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="abc@example.com"
          />
          <br />
          <label for="subject">Subject:</label>
          <br />
          <input
            type="text"
            id="subject"
            name="subject"
            required
            placeholder="Your Subject here.."
          />
          <br />
          <label for="message">Message:</label>
          <br />
          <textarea
            id="message"
            name="message"
            required
            className="message"
            placeholder={`Please Enter the Details like : 
    Your Mobile Number
    Event Date, Theme ,
    Customizations , 
    Number of guests serving
                          `}
          ></textarea>
          <br />
          <br />
          <input
            type="submit"
            value="Send Email"
            className="submit"
            style={{ width: "200px" }}
          />
        </form>
      </div>
    </div>
  );
};

export default Order;
