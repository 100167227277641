import React from "react";
import Navbar from "./Navbar";

const FAQ = () => {
  document.title = "Awesome Cakes | F.A.Q 's";
  return (
    <div style={{ padding: "10px 50px" }}>
      <Navbar />

      <div className="story">
        <h2>Frequently asked Questions</h2>

        <details>
          <summary className="details-overview">
            How much notice do you need ?
          </summary>
          <p>
            THIS DEPENDS ON MY AVAILABILTY, I MAY SOMETIMES BE ABLE TO MAKE CAKE
            AND DESSERTS AT SHORT NOTICE. HOWEVER FOR LARGER CAKES OR ORDER, WE
            REQUIRE MINIMUM 3 WEEKS NOTICE. 
          </p>
        </details>

        <details>
          <summary className="details-overview">
            Do you offer delivery ?
          </summary>
          <p>
            YES, DEPENDING ON LOCATION/ AVAILABITLITY. LOCATION WISE IS
            LAKEVILLE AND THE SURROUNDING CITIES. ALSO ON HOW LARGE THE ORDER
            IS. DELIVERY FEE WILL BE CHARGED ACORDINGLY 
          </p>
        </details>

        <details>
          <summary className="details-overview">
            Do you ship your cakes and desserts ?
          </summary>
          <p>NO, WE DO NOT SHIP ANY OF OUR CAKES AND DESSERTS</p>
        </details>

        <details>
          <summary className="details-overview">
            Where are you located ?
          </summary>
          <p>20584 Geyser CT, Lakeville, Minnesota.</p>
        </details>

        <details>
          <summary className="details-overview">
            Do you cater for Allergies / offer Gluten Free ?
          </summary>
          <p>NO, WE DO NOT CATER FOR ANY OF THE ITEMS ABOVE.</p>
        </details>

        <details>
          <summary className="details-overview">
            How do I place an order ?
          </summary>
          <p>
            YOU CAN EMAIL US THE DETAIL OR YOU CAN TEXT ME ON MY NUMBER OR CHECK
            HOW TO ORDER TAB.
          </p>
        </details>

        <details>
          <summary className="details-overview">
            Do you take deposits ? How much and How much can we pay ?
          </summary>
          <p>
            WE REQUIRE MINIMUM 50% DEPOSIT TO CONFIRM THE ORDER. FAILURE TO MAKE
            THE FULL DEPOSIT BEFORE COLLECTION IS AUTOMATIC CANCELLATION OF THE
            ORDER WE ACCEPT ZELLE OR CASH.
          </p>
        </details>

        <details>
          <summary className="details-overview">
            Do you make wedding cakes and deliver to the venue ?
          </summary>
          <p>
            YES WE DO, THESE CAN BE FOUND ON OUR WEBSITE AND ON OUR INSTAGRAM
            PAGE. DELIVERY IS AVAILABLE AT A COST DEPENDING ON LOCATION.
          </p>
        </details>

        <details>
          <summary className="details-overview">
            Will i get Cake Topper with my order ?
          </summary>
          <p>
            IF CLIENT ASKED FOR A REGULAR BIRTHDAY, BRIDAL, BABY SHOWER TOPPERS
            IT WILL BE FREE OF CHARGE. ANY CUSTOM CAKE TOPPER OR PLAQUE WILL BE
            ADDITONAL CHARGE. ESPECIALLY IF ITS ACRYLIC TOPPERS OR PLAQUES.
          </p>
        </details>
      </div>
    </div>
  );
};

export default FAQ;
