import React from "react";
import Navbar from "./Navbar";
const Flavours = () => {
  document.title = "Awesome Cakes | Flavours";
  return (
    <div className="aboutpage">
      <Navbar />

      <hr className="hrtag-about" />

      <div className="story">
        <h2>We offer a wide range of flavours</h2>
        <p style={{ textAlign: "center" }}>
          Experience the finest in artisanal cakes, all 100% handmade by our
          skilled artisan, Ammu. At our cakery, indulge in a diverse range of
          creations crafted with love and expertise to elevate your sweet
          moments. Whether you're celebrating a special occasion or simply
          craving a sweet treat, our artisan cakery promises to elevate your
          dessert experience to new heights.
        </p>
      </div>

      <div className="story">
        <h2>Vanilla Cake Flavours</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Vanilla Cake with Vanilla Bean whipped Ganache filling</li>
          <li>Vanilla Cake with Milk Chocolate whipped Ganache filling</li>
          <li>Vanilla Cake with Swiss Meringue Buttercream filling</li>
          <li>Vanilla Cake with Salted Caramel filling</li>
          <li>Vanilla Cake with Dulce De Leche filling</li>
          <li>Vanilla Cake with Biscoff cream filling</li>
          <li>Vanilla Cake with Strawberry cream filling</li>
          <li>Vanilla Cake with Raspberry cream filling</li>
          <li>Vanilla Cake with Oreo buttercream filling</li>
          <li>Vanilla Cake with Passion fruit Curd filling (Premium)</li>
        </ul>
        <h2>Chocolate Cake Flavours</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>
            Chocolate Cake with Callebaut Chocolate fudge filling (Premium)
          </li>
          <li>
            Chocolate Cake with Belgian Chocolate Mousse & Orange Caramel
            Filling (Premium)
          </li>
          <li>
            Chocolate Cake with Ferrero Rocher filling (Contains Hazel Nuts)
            (Premium)
          </li>
          <li>Chocolate Cake with Nutella cream filling</li>
          <li>Chocolate Cake with Strawberry cream filling</li>
          <li>Chocolate Cake with Raspberry cream filling</li>
          <li>Chocolate Cake with Caramel filling</li>
          <li>Chocolate Cake with Vanilla Bean whipped Ganache</li>
          <li>Chocolate Cake with Milk Chocolate whipped Ganache filling</li>
          <li>
            Chocolate Cake with Vanilla Bean Swiss Meringue buttercream filling
          </li>
          <li>
            Chocolate Cake with Chocolate Swiss Meringue buttercream filling
          </li>
          <li>Chocolate Cake with Oreo buttercream filling</li>
        </ul>

        <h2>Funfetti </h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Rainbow Sprinkles interspersed a fluffy Vanilla Cake</li>
        </ul>

        <h2>Butterscotch Cake </h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>With Butterscotch cream filling (Optional: Caramelized nuts)</li>
        </ul>

        <h2>Lemon and Lavender Cake(Premium)</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>
            With Tangy Lemon Curd and Lemon Swiss meringue buttercream filling
          </li>
        </ul>

        <h2>Lemon Cake</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Lemon Cake with Lemon Curd filling</li>
          <li>Lemon Cake with Raspberry cream filling</li>
          <li>Lemon Cake with Blueberry Mousse filling</li>
        </ul>

        <h2>Mango Cake</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>With Mango curd filling</li>
        </ul>

        <h2>Marble Cake</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>
            Vanilla & Chocolate swirled cake with Chocolate fudge/Vanilla cream
            filling
          </li>
        </ul>

        <h2>Moist Coconut Mango Cake (Premium)</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>With Custard cream & Mango curd filling</li>
        </ul>

        <h2>Pineapple Cake</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Pineapple Cake with Pineapple curd filling</li>
          <li>Pineapple Cake with Mango curd filling</li>
        </ul>

        <h2>Pistachio Cake (Premium)</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Pistachio Cake with Raspberry cream filling</li>
          <li>Pistachio Cake with Cherry cream filling</li>
        </ul>

        <h2>White Chocolate Rasberry (Premium)</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Vanilla Cake filled with White Chocolate and Raspberries</li>
        </ul>

        <h2>Red Velvet Cake</h2>
        <p
          style={{
            color: "red",
            fontStyle: "italic",
            textAlign: "center",
            marginTop: "-20px",
            fontWeight: "700",
          }}
        >
          (Single tier, Top tiers and Cupcakes only)
        </p>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>With Sweet and Tangy Cream cheese filling</li>
        </ul>

        <h2
          style={{
            fontSize: "40px",
            textDecoration: "underline",
            marginTop: "150px",
          }}
        >
          Desserts
        </h2>
        <p
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginTop: "-20px",
            marginBottom: "40px",
          }}
        >
          Minimum Order Size : A Dozen
        </p>

        <h2>Cakesicles </h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Vanilla/Chocolate/RedVelvet - starting from $ 60/dozen.</li>
        </ul>

        <h2>Cakepops </h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>Vanilla/Chocolate/RedVelvet - starting from $ 50/dozen.</li>
        </ul>

        <h2>Cupcakes</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>
            Cupcake flavors can be chosen from the cake flavor list and
            decorated as per client’s theme - starting from $ 50/dozen.
          </li>
        </ul>

        <h2>Desert Jars</h2>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li>
            Mango delight, Oreo, Lemon, Chocolicious, Chocolate, Cookies &
            Cream, Tiramasu, Strawberry cream trifle, Rasamalai and Gulab Jamun
            – starting $70/dozen. <br />
            <b> Note: </b>Some of the Dessert Jar flavors contains Gelatin.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Flavours;
